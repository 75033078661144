import React, { useRef, useEffect } from "react";
import { useAppState } from "@state/state";
import { Text, RichText, Icon, Image, SectionBreak } from "@atoms";
import { Link, Modal, Button, ContactForm } from "@ui";

/**
 *
 * `TeamModal` Team member details, in a modal
 *
 */

const TeamModal = () => {
  const [{ teamPage }, dispatch] = useAppState();
  const contactFormEl = useRef();
  const bioEl = useRef();
  const { name, title, bio, photo, linkedin } = teamPage.bioModalContent || {};

  useEffect(() => {
    if (contactFormEl.current) {
      contactFormEl.current.scrollTop = 0;
    }
  }, [teamPage.showingBioForm]);

  useEffect(() => {
    if (bioEl.current) {
      bioEl.current.scrollTop = 0;
    }
  }, [teamPage.bioModalContent]);

  return (
    <Modal
      showing={teamPage.showingBioModal}
      setShowing={showingBioModal =>
        dispatch({ type: "setShowingBioModal", showingBioModal })
      }
      closeButtonClassName={
        teamPage.showingBioForm
          ? "text-green-2 hover:text-green-3"
          : "text-white hover:text-green-3"
      }
    >
      {teamPage.showingBioForm ? (
        <div
          ref={contactFormEl}
          className="bg-white px-6 py-8 md:px-16 md:py-12 max-w-mdLg"
        >
          <ContactForm initialContact={name} />
        </div>
      ) : (
        <div
          ref={bioEl}
          className="bg-green-2 max-w-md px-6 py-8 md:p-12 lg:p-16"
        >
          {teamPage.bioModalContent && (
            <>
              <div className="flex items-center">
                <div className="relative w-32 h-32 md:w-40 md:h-40 rounded-full overflow-hidden image-overflow-mask">
                  <Image image={photo} />
                </div>

                <div className="ml-6">
                  <Text
                    tag="h3"
                    use="label"
                    className="text-green-3 group-hover:text-green-3 mb-1 transition"
                  >
                    {name}
                  </Text>

                  <Text
                    tag="label"
                    options={{ size: "text-sm", style: "capitalize" }}
                    className="block text-gray-3 mb-2 cursor-pointer"
                  >
                    {title}
                  </Text>

                  <Link to={linkedin.url} target="_blank">
                    <Icon name="LinkedIn" className="w-4 h-4 text-white" />
                  </Link>
                </div>
              </div>

              <SectionBreak className="w-full my-6 md:my-8 text-green-3" />

              <RichText html={bio.html} className="RichText--sm text-white" />

              <Button
                preset="dark"
                text={`Email ${name.split(" ")[0]}`}
                className="mt-6 md:mt-10"
                onClick={() =>
                  dispatch({ type: "setShowingBioForm", showingBioForm: true })
                }
              />
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default TeamModal;
