import React from "react";
import PropTypes from "prop-types";
import { Text, Image, imageProps } from "@atoms";

const TeamNetworkMember = ({ name, title, photo, className }) => {
  return (
    <div className={`w-1/2 md:w-1/4 lg:w-1/5 p-6 ${className}`}>
      <div className="flex flex-col items-center">
        <div className="relative w-24 h-24 rounded-full overflow-hidden image-overflow-mask mb-3">
          <Image image={photo} />
        </div>

        <Text
          tag="h4"
          use="label"
          options={{ size: "text-sm" }}
          className="text-green text-center"
        >
          {name}
        </Text>

        <Text
          tag="label"
          options={{ size: "text-xs", style: "capitalize" }}
          className="text-gray text-center"
        >
          {title}
        </Text>
      </div>
    </div>
  );
};

TeamNetworkMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  photo: imageProps.isRequired,
  className: PropTypes.string,
};

TeamNetworkMember.defaultProps = {
  className: "",
};

export default TeamNetworkMember;
