import React from "react";
import { useTeamPageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import {
  Section,
  Container,
  RawText,
  Pattern,
  Triangle,
  StaggerIn,
  Text,
} from "@atoms";
import {
  CopyBlock,
  ImageBlock,
  CopyImageBlock,
  TestimonialFader,
} from "@composites";
import TeamMember from "./TeamMember";
import TeamNetworkMember from "./TeamNetworkMember";
import TeamModal from "./TeamModal";

const TeamPage = pageProps => {
  const {
    hero,
    overview,
    team,
    network,
    testimonials,
    prefooter,
    meta,
  } = useTeamPageData();

  return (
    <DefaultLayout {...pageProps} meta={meta} theme="light">
      {/* Hero */}
      <Section className="relative bg-gray-5" size="xl">
        <Container className="relative z-20">
          <RawText items={hero.heading.raw} className="text-green-2 max-w-sm" />
        </Container>

        <Pattern
          orientation="bottom-left"
          size="sm"
          className="text-gray-3"
          doAnimation
        />
      </Section>

      {/* Overview */}
      <Section className="relative bg-white">
        <Pattern
          orientation="top-left"
          size="sm"
          className="text-gray-3 z-20"
        />

        <Container className="relative z-30">
          <CopyImageBlock
            orientation="right"
            copy={({ className, style }) => (
              <CopyBlock
                {...overview}
                preset="dark"
                className={className}
                style={style}
              />
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={overview.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>

        <Pattern
          orientation="bottom-right"
          size="sm"
          className="text-gray-3 z-20"
        />
      </Section>

      {/* Team */}
      <Section className="bg-green-1">
        <Pattern
          orientation="top-right"
          size="sm"
          className="text-gray-3 z-20"
        />

        <Container className="relative z-30 mb-8 md:mb-16">
          <Text tag="label" className="block text-green-3 mb-4">
            {team.subheading}
          </Text>

          <RawText items={team.heading.raw} className="text-white" />
        </Container>

        <Container>
          <StaggerIn className="relative z-30 flex flex-wrap items-start justify-center -mx-3 mb-12">
            {team.people.map(person => (
              <TeamMember key={person.id} {...person} />
            ))}
          </StaggerIn>
        </Container>
      </Section>

      {/* Team Modal */}
      <TeamModal />

      {/* Network */}
      <Section className="bg-white" hasPaddingTop={false} allowOverflow>
        <TestimonialFader
          testimonials={testimonials}
          className="relative z-30 -translate-y-16"
        />

        <Container className="my-8 md:my-16">
          <Text tag="label" className="block text-green-3 mb-4">
            {network.subheading}
          </Text>

          <RawText items={network.heading.raw} className="text-green-2" />
        </Container>

        <Container>
          <StaggerIn className="flex flex-wrap items-start justify-center -mx-3">
            {network.people.map(person => (
              <TeamNetworkMember key={person.id} {...person} />
            ))}
          </StaggerIn>
        </Container>
      </Section>

      {/* Prefooter */}
      <Section className="bg-white" allowOverflow>
        <Triangle orientation="bottom-right" className="text-gray-5 -mt-48" />
        <Container class="relative z-20">
          <CopyImageBlock
            orientation="left"
            className="bg-green shadow-section"
            copy={({ style }) => (
              <div className="relative overflow-hidden">
                <CopyBlock
                  {...prefooter}
                  preset="light"
                  className="relative z-10 px-6 pb-6 sm:px-8 sm:pb-8 md:p-16"
                  style={style}
                />
                <Pattern
                  orientation="bottom-left"
                  size="sm"
                  className="text-gray-2"
                />
              </div>
            )}
            image={({ className, style }) => (
              <ImageBlock
                image={prefooter.image}
                className={className}
                style={style}
              />
            )}
          />
        </Container>
      </Section>
    </DefaultLayout>
  );
};

export default TeamPage;
