import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { Text, Image, imageProps, SectionBreak } from "@atoms";

const TeamMember = ({ name, title, photo, bio, linkedin, className }) => {
  const [, dispatch] = useAppState();

  const handleClick = () => {
    const bioModalContent = {
      name,
      title,
      photo,
      bio,
      linkedin,
    };
    dispatch({ type: "setBioModalContent", bioModalContent });
    setTimeout(() => {
      dispatch({ type: "setShowingBioModal", showingBioModal: true });
    }, 50);
  };

  return (
    <div className={`w-1/2 md:w-1/3 p-2 ${className}`}>
      <button
        type="button"
        className="w-full h-full p-4 md:py-8 outline-none focus:outline-none group border border-transparent hover:border-green-3-40 hover:bg-green-2 transition"
        onClick={handleClick}
      >
        <div className="flex flex-col items-center">
          <div className="relative w-32 h-32 md:w-40 md:h-40 rounded-full overflow-hidden image-overflow-mask">
            <Image image={photo} />
          </div>

          <SectionBreak className="my-4 text-green-3" />

          <Text
            tag="h3"
            use="label"
            className="text-white group-hover:text-green-3 mb-2 transition"
          >
            {name}
          </Text>

          <Text
            tag="label"
            options={{ size: "text-sm", style: "capitalize" }}
            className="text-gray-3 mb-2 cursor-pointer"
          >
            {title}
          </Text>
        </div>
      </button>
    </div>
  );
};

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
  photo: imageProps.isRequired,
  linkedin: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

TeamMember.defaultProps = {
  className: "",
};

export default TeamMember;
